export let FRCommonWords = [
  "AGENT",
  "AIDER",
  "AIMER",
  "AINSI",
  "ALLER",
  "ALORS",
  "AMOUR",
  "ANNEE",
  "APPEL",
  "APRES",
  "APRES",
  "ARBRE",
  "ARMEE",
  "ARMER",
  "ASSEZ",
  "AUCUN",
  "AUCUN",
  "AUSSI",
  "AUTRE",
  "AUTRE",
  "AVANT",
  "AVANT",
  "AVOIR",
  "BANDE",
  "BARBE",
  "BEAUX",
  "BLANC",
  "BLANC",
  "BLOND",
  "BOIRE",
  "BRUIT",
  "CALME",
  "CALME",
  "CARTE",
  "CAUSE",
  "CEDER",
  "CELUI",
  "CESSE",
  "CHAIR",
  "CHAMP",
  "CHANT",
  "CHAUD",
  "CHIEN",
  "CHOIX",
  "CHOSE",
  "CHUTE",
  "CLAIR",
  "COEUR",
  "COLON",
  "COMME",
  "COMME",
  "CORDE",
  "CORPS",
  "COURS",
  "COURT",
  "CREER",
  "CRIER",
  "CRISE",
  "CROIX",
  "CRUEL",
  "DEBUT",
  "DESIR",
  "DIGNE",
  "DOIGT",
  "DOUTE",
  "DOUZE",
  "DRAME",
  "DROIT",
  "DROIT",
  "DROLE",
  "DURER",
  "ECLAT",
  "ECOLE",
  "EFFET",
  "ENFIN",
  "ENTRE",
  "ENVIE",
  "EPAIS",
  "ETAGE",
  "ETUDE",
  "FAÇON",
  "FAIRE",
  "FAUTE",
  "FEMME",
  "FERME",
  "FILLE",
  "FINIR",
  "FIXER",
  "FLEUR",
  "FOLIE",
  "FORCE",
  "FORET",
  "FORME",
  "FOULE",
  "FRAIS",
  "FRANC",
  "FRERE",
  "FROID",
  "FROID",
  "FRONT",
  "FRUIT",
  "FUMEE",
  "FUMER",
  "FUSIL",
  "GARDE",
  "GENOU",
  "GENRE",
  "GESTE",
  "GLACE",
  "GRACE",
  "GRACE",
  "GRAIN",
  "GRAND",
  "GRAND",
  "GRAVE",
  "GUERE",
  "HAINE",
  "HAUTE",
  "HERBE",
  "HEURE",
  "HIVER",
  "HOMME",
  "HONTE",
  "HOTEL",
  "IMAGE",
  "JAMBE",
  "JAUNE",
  "JETER",
  "JEUNE",
  "JEUNE",
  "JOUER",
  "JUGER",
  "JUSTE",
  "LARGE",
  "LARME",
  "LEGER",
  "LEVER",
  "LEVRE",
  "LIBRE",
  "LIGNE",
  "LIVRE",
  "LOURD",
  "LUEUR",
  "L'UNE",
  "LUTTE",
  "MASSE",
  "MATIN",
  "MELER",
  "MENER",
  "MIEUX",
  "MILLE",
  "MINCE",
  "MISER",
  "MOINS",
  "MONDE",
  "MOYEN",
  "MOYEN",
  "NOIRE",
  "NOTRE",
  "NUAGE",
  "OBEIR",
  "OBJET",
  "ODEUR",
  "OMBRE",
  "ONCLE",
  "ORDRE",
  "PARMI",
  "PARTI",
  "PASSE",
  "PASSE",
  "PAYER",
  "PEINE",
  "PERDU",
  "PERTE",
  "PESER",
  "PETIT",
  "PETIT",
  "PIECE",
  "PITIE",
  "PLACE",
  "PLEIN",
  "PLEIN",
  "PLUIE",
  "POCHE",
  "POETE",
  "POIDS",
  "POINT",
  "POINT",
  "PORTE",
  "POSER",
  "POSTE",
  "PRIER",
  "QUAND",
  "QUART",
  "QUEUE",
  "RAYON",
  "REGLE",
  "REPAS",
  "RESTE",
  "REVER",
  "RICHE",
  "ROCHE",
  "ROMAN",
  "ROUGE",
  "ROUGE",
  "ROUTE",
  "RUINE",
  "SABLE",
  "SAINT",
  "SAINT",
  "SALLE",
  "SALUT",
  "SANTE",
  "SCENE",
  "SELON",
  "SEUIL",
  "SIEGE",
  "SIGNE",
  "SOMME",
  "SORTE",
  "SOURD",
  "SUBIR",
  "SUEUR",
  "SUITE",
  "SUJET",
  "TABLE",
  "TACHE",
  "TACHE",
  "TAIRE",
  "TAPIS",
  "TEMPS",
  "TENIR",
  "TERME",
  "TERRE",
  "TIRER",
  "TITRE",
  "TOILE",
  "TOMBE",
  "TOUTE",
  "TRACE",
  "TRAIN",
  "TRAIT",
  "TROIS",
  "USAGE",
  "VAGUE",
  "VAGUE",
  "VASTE",
  "VENIR",
  "VERRE",
  "VETIR",
  "VIEIL",
  "VIEUX",
  "VIEUX",
  "VILLE",
  "VINGT",
  "VIVRE",
  "VOICI",
  "VOILA",
  "VOILE",
  "VOLER",
  "VOTRE",
];
