export let CommonWords = [
"ABOUT",
"ABOVE",
"ABUSE",
"ACTOR",
"ACUTE",
"ADAPT",
"ADMIT",
"ADOPT",
"ADULT",
"AFTER",
"AGAIN",
"AGENT",
"AGREE",
"AHEAD",
"ALARM",
"ALBUM",
"ALERT",
"ALIEN",
"ALIGN",
"ALIKE",
"ALIVE",
"ALLOW",
"ALONE",
"ALONG",
"ALTER",
"AMEND",
"AMONG",
"ANGEL",
"ANGER",
"ANGLE",
"ANGRY",
"ANKLE",
"ANNOY",
"APART",
"APPLE",
"APPLY",
"APRIL",
"ARENA",
"ARGUE",
"ARISE",
"ARMED",
"ARRAY",
"ARROW",
"ASIDE",
"ASSET",
"AUDIO",
"AUDIT",
"AVOID",
"AWAIT",
"AWARD",
"AWARE",
"AWFUL",
"BADGE",
"BADLY",
"BASED",
"BASIC",
"BASIS",
"BEACH",
"BEAST",
"BEGIN",
"BEING",
"BELOW",
"BENCH",
"BIRTH",
"BLACK",
"BLADE",
"BLAME",
"BLANK",
"BLAST",
"BLEED",
"BLEND",
"BLESS",
"BLIND",
"BLOCK",
"BLOOD",
"BOARD",
"BOAST",
"BONUS",
"BOOST",
"BORED",
"BOUND",
"BRAIN",
"BRAND",
"BRAVE",
"BREAD",
"BREAK",
"BREED",
"BRICK",
"BRIDE",
"BRIEF",
"BRING",
"BROAD",
"BROWN",
"BRUSH",
"BUDDY",
"BUILD",
"BUNCH",
"BURST",
"CABIN",
"CABLE",
"CANAL",
"CARGO",
"CARRY",
"CARVE",
"CATCH",
"CATER",
"CAUSE",
"CEASE",
"CHAIN",
"CHAIR",
"CHAOS",
"CHARM",
"CHART",
"CHASE",
"CHEAP",
"CHEAT",
"CHECK",
"CHEEK",
"CHEER",
"CHEST",
"CHIEF",
"CHILD",
"CHOIR",
"CHUNK",
"CIVIC",
"CIVIL",
"CLAIM",
"CLASH",
"CLASS",
"CLEAN",
"CLEAR",
"CLERK",
"CLICK",
"CLIFF",
"CLIMB",
"CLING",
"CLOCK",
"CLOSE",
"CLOTH",
"CLOUD",
"COACH",
"COAST",
"COMIC",
"COULD",
"COUNT",
"COURT",
"COVER",
"CRACK",
"CRAFT",
"CRASH",
"CRAWL",
"CRAZY",
"CREAM",
"CREEP",
"CRIME",
"CROSS",
"CROWD",
"CROWN",
"CRUDE",
"CRUEL",
"CRUSH",
"CURLY",
"CURVE",
"CYCLE",
"DAILY",
"DAIRY",
"DANCE",
"DEATH",
"DEBUT",
"DELAY",
"DEMON",
"DENSE",
"DEPTH",
"DEVIL",
"DIARY",
"DIRTY",
"DONOR",
"DOUBT",
"DOZEN",
"DRAFT",
"DRAIN",
"DRAMA",
"DREAM",
"DRESS",
"DRIFT",
"DRINK",
"DRIVE",
"DROWN",
"DRUNK",
"EAGER",
"EARLY",
"EARTH",
"EIGHT",
"ELBOW",
"ELECT",
"ELITE",
"EMAIL",
"EMBED",
"EMPTY",
"ENACT",
"ENEMY",
"ENJOY",
"ENROL",
"ENSUE",
"ENTER",
"ENTRY",
"EQUAL",
"EQUIP",
"ERECT",
"ERROR",
"ERUPT",
"ESSAY",
"ETHIC",
"EVENT",
"EVERY",
"EVOKE",
"EXACT",
"EXERT",
"EXILE",
"EXIST",
"EXTRA",
"FAITH",
"FALSE",
"FANCY",
"FATAL",
"FAULT",
"FENCE",
"FEVER",
"FIBRE",
"FIELD",
"FIFTH",
"FIFTY",
"FIGHT",
"FINAL",
"FIRST",
"FIXED",
"FLAME",
"FLASH",
"FLEET",
"FLESH",
"FLOAT",
"FLOOD",
"FLOOR",
"FLOUR",
"FLUID",
"FOCUS",
"FORCE",
"FORGE",
"FORTH",
"FORTY",
"FORUM",
"FOUND",
"FRAME",
"FRAUD",
"FRESH",
"FRONT",
"FRUIT",
"FULLY",
"FUNNY",
"GENRE",
"GHOST",
"GIANT",
"GLASS",
"GLOBE",
"GLORY",
"GLOVE",
"GOODS",
"GRACE",
"GRADE",
"GRAIN",
"GRAND",
"GRANT",
"GRASP",
"GRASS",
"GRAVE",
"GREAT",
"GREEN",
"GREET",
"GRIEF",
"GRIND",
"GROSS",
"GROUP",
"GUARD",
"GUESS",
"GUEST",
"GUIDE",
"GUILT",
"HABIT",
"HANDY",
"HAPPY",
"HARSH",
"HAUNT",
"HEART",
"HEAVY",
"HELLO",
"HENCE",
"HOBBY",
"HORSE",
"HOTEL",
"HOUSE",
"HUMAN",
"HURRY",
"IDEAL",
"IDIOT",
"IMAGE",
"IMPLY",
"INCUR",
"INDEX",
"INFER",
"INNER",
"INPUT",
"IRONY",
"ISSUE",
"JEANS",
"JOINT",
"JUDGE",
"JUICE",
"KNIFE",
"KNOCK",
"LABEL",
"LARGE",
"LASER",
"LATER",
"LAUGH",
"LAYER",
"LEARN",
"LEAST",
"LEAVE",
"LEGAL",
"LEMON",
"LEVEL",
"LIGHT",
"LIMIT",
"LITRE",
"LIVER",
"LOBBY",
"LOCAL",
"LOGIC",
"LOOSE",
"LORRY",
"LOWER",
"LOYAL",
"LUCKY",
"LUNCH",
"LYRIC",
"MAGIC",
"MAJOR",
"MARCH",
"MARRY",
"MATCH",
"MATHS",
"MAYBE",
"MAYOR",
"MEANS",
"MEDAL",
"MEDIA",
"MERCY",
"MERGE",
"MERIT",
"METAL",
"METRE",
"MIDST",
"MIGHT",
"MINER",
"MINOR",
"MIXED",
"MODEL",
"MONEY",
"MONTH",
"MORAL",
"MOTOR",
"MOUNT",
"MOUSE",
"MOUTH",
"MOVIE",
"MUSIC",
"NAKED",
"NASTY",
"NAVAL",
"NERVE",
"NEVER",
"NEWLY",
"NICHE",
"NIGHT",
"NOBLE",
"NOISE",
"NOISY",
"NORTH",
"NOVEL",
"NURSE",
"OCCUR",
"OCEAN",
"OFFER",
"OFTEN",
"ONION",
"OPERA",
"ORDER",
"ORGAN",
"OTHER",
"OUGHT",
"OUTER",
"OWNER",
"PAINT",
"PANEL",
"PANIC",
"PANTS",
"PAPER",
"PARTY",
"PATCH",
"PAUSE",
"PEACE",
"PENNY",
"PHASE",
"PHONE",
"PHOTO",
"PIANO",
"PIECE",
"PILOT",
"PITCH",
"PLACE",
"PLAIN",
"PLANE",
"PLANT",
"PLATE",
"PLEAD",
"POINT",
"POUND",
"POWER",
"PRESS",
"PRICE",
"PRIDE",
"PRIME",
"PRINT",
"PRIOR",
"PRIZE",
"PROBE",
"PROOF",
"PROUD",
"PROVE",
"PULSE",
"PUNCH",
"PUPIL",
"QUEEN",
"QUERY",
"QUEST",
"QUEUE",
"QUICK",
"QUIET",
"QUITE",
"QUOTA",
"QUOTE",
"RADAR",
"RADIO",
"RAISE",
"RALLY",
"RANGE",
"RAPID",
"RATIO",
"REACH",
"REACT",
"READY",
"REALM",
"REBEL",
"REFER",
"REIGN",
"RELAX",
"RENEW",
"REPLY",
"RIFLE",
"RIGHT",
"RISKY",
"RIVAL",
"RIVER",
"ROBOT",
"ROUGH",
"ROUND",
"ROUTE",
"ROYAL",
"RUGBY",
"RURAL",
"SADLY",
"SAINT",
"SALAD",
"SAUCE",
"SCALE",
"SCARE",
"SCARY",
"SCENE",
"SCOPE",
"SCORE",
"SCREW",
"SEIZE",
"SENSE",
"SERVE",
"SEVEN",
"SHADE",
"SHAKE",
"SHALL",
"SHAME",
"SHAPE",
"SHARE",
"SHARP",
"SHEEP",
"SHEER",
"SHEET",
"SHELF",
"SHELL",
"SHIFT",
"SHINE",
"SHINY",
"SHIRT",
"SHOCK",
"SHOOT",
"SHORE",
"SHORT",
"SHOUT",
"SHRUG",
"SIGHT",
"SILLY",
"SINCE",
"SIXTY",
"SKILL",
"SKIRT",
"SKULL",
"SLASH",
"SLAVE",
"SLEEP",
"SLICE",
"SLIDE",
"SLOPE",
"SMALL",
"SMART",
"SMASH",
"SMELL",
"SMILE",
"SMOKE",
"SNAKE",
"SOLAR",
"SOLID",
"SOLVE",
"SORRY",
"SOUND",
"SOUTH",
"SPACE",
"SPARE",
"SPARK",
"SPEAK",
"SPEED",
"SPELL",
"SPEND",
"SPICE",
"SPICY",
"SPILL",
"SPINE",
"SPITE",
"SPLIT",
"SPOIL",
"SPOON",
"SPORT",
"SQUAD",
"STAFF",
"STAGE",
"STAIR",
"STAKE",
"STALL",
"STAMP",
"STAND",
"STARE",
"STARK",
"START",
"STATE",
"STEAL",
"STEAM",
"STEEL",
"STEEP",
"STEER",
"STICK",
"STIFF",
"STILL",
"STOCK",
"STONE",
"STORE",
"STORM",
"STORY",
"STRIP",
"STUDY",
"STUFF",
"STYLE",
"SUGAR",
"SUITE",
"SUPER",
"SURGE",
"SWEAR",
"SWEEP",
"SWEET",
"SWING",
"SWORD",
"TABLE",
"TASTE",
"TEACH",
"TEENS",
"TEMPT",
"TERMS",
"THANK",
"THEFT",
"THEIR",
"THEME",
"THERE",
"THICK",
"THIEF",
"THING",
"THINK",
"THIRD",
"THREE",
"THROW",
"THUMB",
"TIGHT",
"TIRED",
"TITLE",
"TODAY",
"TONNE",
"TOOTH",
"TOPIC",
"TOTAL",
"TOUCH",
"TOUGH",
"TOWEL",
"TOWER",
"TOXIC",
"TRACE",
"TRACK",
"TRADE",
"TRAIL",
"TRAIN",
"TRAIT",
"TREAT",
"TREND",
"TRIAL",
"TRIBE",
"TRICK",
"TROOP",
"TRUCK",
"TRULY",
"TRUST",
"TRUTH",
"TWICE",
"TWIST",
"UNCLE",
"UNDER",
"UNIFY",
"UNION",
"UNITE",
"UNITY",
"UNTIL",
"UPPER",
"UPSET",
"URBAN",
"USAGE",
"USUAL",
"VAGUE",
"VALID",
"VALUE",
"VENUE",
"VERSE",
"VIDEO",
"VIRUS",
"VISIT",
"VITAL",
"VOCAL",
"VOICE",
"WASTE",
"WATCH",
"WATER",
"WEAVE",
"WEIGH",
"WEIRD",
"WHEAT",
"WHEEL",
"WHERE",
"WHICH",
"WHILE",
"WHITE",
"WHOLE",
"WHOSE",
"WIDEN",
"WIDOW",
"WIDTH",
"WOMAN",
"WORLD",
"WORRY",
"WORSE",
"WORST",
"WORTH",
"WOULD",
"WOUND",
"WRIST",
"WRITE",
"WRONG",
"YIELD",
"YOUNG",
"YOURS",
"YOUTH"];